import React from "react";
import { HomePageData } from "../API/HomeApi";
import WeHelpWith from "../Modules/Home/WeHelpWith";
import Workflow from "../Modules/Home/Workflow";
import Achievements from "../SharedModule/Achievements";
import Slider from "../Modules/Slider";
import Carousel from "../Modules/Home/Carousel";
import OurClientNew from "../SharedModule/OurClientNew";
import OurWork from "../Modules/Home/OurWork";
import TestimonialNew from "../SharedModule/TestimonialNew";
import WhoWeAre from "../Modules/WhoWeAre/WhoWeAre";
const Home = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Slider />
      <WhoWeAre />
      <Workflow />
      <WeHelpWith />
      <OurClientNew />
      <TestimonialNew />
      <Achievements />
      <Carousel />
      <OurWork />
    </>
  );
};

export default Home;
