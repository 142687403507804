import React from "react";
import "./CustomeSoftware.css";
import shape2 from "../../../images/SoftwareDev/shape2.svg";
import shape3 from "../../../images/SoftwareDev/shape3.svg";
import shape4 from "../../../images/SoftwareDev/shape4.svg";
import softwareproduct from "../../../images/SoftwareDev/software product development.png";
import Dedicated from "../../../images/SoftwareDev/dedicated team.png";
import softwareIntegration from "../../../images/SoftwareDev/software integration service.png";
import Outsourcing from "../../../images/SoftwareDev/outsourcing software development.png";
import ProductConsulting from "../../../images/SoftwareDev/product consulting.png";
import Maintenance from "../../../images/SoftwareDev/maintenance and support.png";
import softwareDev from "../../../images/SoftwareDev/SoftD.png";
import client9 from "../../../images/Pastproject/client9.webp";
import client10 from "../../../images/Pastproject/client10.webp";
import client11 from "../../../images/Pastproject/CiscoLogo.png";
import IndustrySection from "./IndustrySection";
import asset1 from "../../../images/SoftwareDev/Asset-1.svg";
import asset2 from "../../../images/SoftwareDev/Asset-2.svg";
import asset3 from "../../../images/SoftwareDev/Asset-3.svg";
import asset4 from "../../../images/SoftwareDev/Asset-4.svg";
import InsightSection from "../SaaSDev/InsightSection";

const CustomeSoftware = () => {
  return (
    <>
      <div className="CustomeDev">
        <section>
          <div className="main-banner">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <h1 className="Product-title">Custom Software Development</h1>
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="content-para">
                    With Senwell Group Private Limited, you can gain a competitive edge for
                    your particular and complicated business needs. Using
                    technology innovation, scalability, and agility, leverage
                    our ability to create dependable and flexible custom
                    software solutions and applications that hasten businesses'
                    digital transformation processes.
                  </p>
                </div>
              </div>
            </div>

            <div class="shape2 rotateme">
              <img src={shape2} alt="img" />
            </div>

            <div className="shape3">
              <img src={shape3} alt="shape" />
            </div>
            <div className="shape4">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape5">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape6 rotateme">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape7">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape8 rotateme">
              <img src={shape2} alt="shape" />
            </div>
          </div>
        </section>
      </div>

      <section className="section-services">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row" style={{ marginBottom: "60px" }}>
            <div className="col-lg-12 col-md-12 text-center">
              <h2 className="section-head">
                Custom Software Development Services
              </h2>
              <p className="section-para">
                Our custom business software solutions provide a wide range of
                services to various businesses.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 work-grid">
              <div className="work-itemProduct">
                <div className="icon">
                  <img src={ProductConsulting} alt="img" />
                </div>
                <h3>Bespoke Software Development</h3>

                <p>
                  Excellent custom software development services, technical
                  expertise, and knowledgeable resources are paired to enhance
                  overall business strategy. Using a proven prototyping
                  methodology and comprehensive custom development plan, we
                  build custom applications from the ground up for mobile, web,
                  and cloud platforms, guaranteeing smooth integration,
                  adaptability, and financial success for our clients.
                </p>
              </div>
            </div>
            <div className="col-lg-4 work-grid">
              <div className="work-itemProduct">
                <div className="icon">
                  <img src={softwareproduct} alt="img" />
                </div>
                <h3>Application Migration and Re-Engineering</h3>

                <p>
                  Upgrade current software solutions and increase efficiency by
                  repurposing, rearranging, or transferring infrastructure to
                  fully functional, fully customized applications. With
                  cutting-edge enhancements of new features, enhanced UI/UX, and
                  contemporary security based on cutting-edge technologies, our
                  software engineers guarantee flawless re-engineering of
                  software.
                </p>
              </div>
            </div>

            <div className="col-lg-4 work-grid">
              <div className="work-itemProduct">
                <div className="icon">
                  <img src={Outsourcing} alt="img" />
                </div>
                <h3>Software Application Maintenance</h3>

                <p>
                  By performing routine software maintenance, upgrades, and
                  performance optimization, you can help your software
                  application reach its full potential. With regular software
                  updates and real-time issue resolution, our custom software
                  development services let you easily manage all
                  post-development tasks and produce high-performing apps.
                </p>
              </div>
            </div>

            <div className="col-lg-4 work-grid">
              <div className="work-itemProduct">
                <div className="icon">
                  <img src={Dedicated} alt="img" />
                </div>
                <h3>SaaS Applications</h3>

                <p>
                  To solve your unique business challenges and achieve increased
                  productivity, lower risks, a competitive edge, and an improved
                  customer experience, take advantage of our many years of
                  experience developing commercial SaaS-based applications. We
                  have extensive experience developing serverless and
                  cloud-based SaaS applications utilizing a range of frameworks
                  and technologies.
                </p>
              </div>
            </div>

            <div className="col-lg-4 work-grid">
              <div className="work-itemProduct">
                <div className="icon">
                  <img src={softwareIntegration} alt="img" />
                </div>
                <h3>Application Integration</h3>

                <p>
                  end-to-end application integration services to improve the
                  workflow that impedes the advancement of business between
                  various business sources. Modern services between offline or
                  on-premise cloud data environments are provided by our
                  software integration engineers with customization to enable
                  more flexible and seamless business operations at a reduced
                  integration cost.
                </p>
              </div>
            </div>

            <div className="col-lg-4 work-grid">
              <div className="work-itemProduct">
                <div className="icon">
                  <img src={Maintenance} alt="img" />
                </div>
                <h3>Maintenance and Support</h3>

                <p>
                  Enhance application performance by implementing revolutionary
                  modifications during software upgrades and maintenance that
                  are customized to meet individual customer requirements. We
                  assist clients with custom software support at various levels,
                  such as cloud support for seamless business continuity, web
                  services support, operation system integration changes, and
                  infrastructure changes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding-product">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row align-items-lg-center pt-md-3 pb-2 mb-2 mb-lg-4 mb-xl-2">
            <div className="col-md-12 col-lg-5 mb-4 mb-md-0 ">
              <img src={softwareDev} alt="img" className="leftimg" />
            </div>

            <div className="col-md-12 col-lg-6 offset-lg-1">
              <div className="pe-xl-2 me-md-2 me-lg-2">
                <h2 className="h4 mb-3 mb-sm-2 design-and-content-header">
                  Top Custom Software Development Services
                </h2>

                <p class="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                  As a reputable custom software development company, we are
                  aware that custom software is created with particular business
                  goals, procedures, and branding in mind. It has a variety of
                  special features depending on what your business needs.
                </p>

                <p class="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                  Our custom software development services are centered on
                  creating scalable and adaptable custom applications in the
                  face of a changing business environment.
                </p>

                <p class="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                  Our client-friendly policies and methodical approach enable us
                  to provide affordable and exceptional custom software
                  development services. If you bring us your idea, we're sure we
                  can use our extensive business background and understanding of
                  the market to your advantage for the creation of custom
                  software.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-padding clients-section ">
        <div class="container" style={{ left: "0", border: "none" }}>
          <div class="row align-items-center">
            <div class="col-lg-4 pr-lg-5">
              <h2 class="section-heading">Our Leading Multinational Clients</h2>
              <p>
                Join our diverse client base for a digital transformation
                journey
              </p>
            </div>
            <div class="col-lg-8">
              <div class="clients-logo-list" style={{ display: "flex" }}>
                <div class="logo-col">
                  <div class="logo-image">
                    <img
                      width="250"
                      height="102"
                      src={client9}
                      alt="MindLift Industries Logo"
                      title="MindLife"
                      class="entered lazyloaded"
                    />
                  </div>
                </div>
                <div class="logo-col">
                  <div class="logo-image">
                    <img
                      width="250"
                      height="102"
                      src={client10}
                      alt="worldbank Logo | senwell"
                      title="worldbank"
                      data-ll-status="loaded"
                      class="entered lazyloaded"
                    />
                  </div>
                </div>
                <div class="logo-col">
                  <div class="logo-image">
                    <img
                      width="250"
                      height="102"
                      src={client11}
                      alt="cisco"
                      title="client11"
                      data-ll-status="loaded"
                      class="entered lazyloaded"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <IndustrySection />

      <section className="section-services">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row">
            <div className="col-lg-12">
              <h2 class="section-title text-center">
                The Value We Offer Our Clients
              </h2>

              <h3 class="section-subtitle">
                We ensure your experience with us is hassle-free and
                transparent.
              </h3>
            </div>
          </div>

          <div class="row justify-content-center" style={{ rowGap: "20px" }}>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="value-we-offer-col-main">
                <div class="value-we-offer-col">
                  <div class="vwo-icon">
                    <img src={asset1} alt="img" />
                  </div>
                  <h3>Consultative Approach</h3>

                  <p>
                    We can provide a distinct and consultative perspective on
                    software engineering that you won't find with other
                    development and technology consulting providers because we
                    have over ten years of industry experience working with over
                    a thousand clients.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-6">
              <div class="value-we-offer-col-main">
                <div class="value-we-offer-col">
                  <div class="vwo-icon">
                    <img src={asset2} alt="img" />
                  </div>
                  <h3>All-in-One Provider </h3>

                  <p>
                    We can handle all of your needs, from development to
                    software testing to cloud services, end-to-end support and
                    maintenance, developing AI solutions, and much more, thanks
                    to the breadth of our services.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-6">
              <div class="value-we-offer-col-main">
                <div class="value-we-offer-col">
                  <div class="vwo-icon">
                    <img src={asset3} alt="img" />
                  </div>
                  <h3>Minimize Development Costs</h3>

                  <p>
                    Our development services are dependable and reasonably
                    priced due to cloud-based architectures, readily available
                    frameworks and platforms, and open APIs. Our Agile processes
                    reduce the possibility of rework and improve the final
                    product.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-6">
              <div class="value-we-offer-col-main">
                <div class="value-we-offer-col">
                  <div class="vwo-icon">
                    <img src={asset4} alt="img" />
                  </div>
                  <h3>Long Term Partnership</h3>

                  <p>
                    Even after providing and setting up our best custom software
                    solutions, we don't stop. Beyond that, we establish a
                    long-term partnership by providing consistent maintenance
                    support to ensure the smooth operation of your company.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <InsightSection />
    </>
  );
};

export default CustomeSoftware;
