import React from "react";
import "./SaasDev.css";
import shape2 from "../../../images/SoftwareDev/shape2.svg";
import shape3 from "../../../images/SoftwareDev/shape3.svg";
import shape4 from "../../../images/SoftwareDev/shape4.svg";
import { Tabs, Tab } from "react-bootstrap";
import ProductConsulting from "../../../images/SoftwareDev/product consulting.png";
import prototype from "../../../images/SoftwareDev/prototype and design.png";
import Maintenance1 from "../../../images/SoftwareDev/maintenance and support.png";
import SoftwareProTesting from "../../../images/SoftwareDev/software product testing.png";
import ArchitectureD from "../../../images/SoftwareDev/Architecture design.png";
import SaaSApplication from "../../../images/SoftwareDev/Saas.png";
import client9 from "../../../images/Pastproject/client9.webp";
import client10 from "../../../images/Pastproject/client10.webp";
import client11 from "../../../images/Pastproject/CiscoLogo.png";
import js from "../../../images/SoftwareDev/javascript-logo.svg";
import css3 from "../../../images/SoftwareDev/css3-colored-logo.svg";
import Html from "../../../images/SoftwareDev/html5-colored-logo.svg";
import emberjs from "../../../images/SoftwareDev/Emberjs-colored.svg";
import nestjs from "../../../images/Technologies/Nest-bgremove.png";
import java from "../../../images/SoftwareDev/Java-icon.svg";
import msdotnet from "../../../images/SoftwareDev/Microsoft_.NET-colored-logo.svg";
import Ionic from "../../../images/SoftwareDev/Ionic-colored-logo.svg";
import Android from "../../../images/SoftwareDev/Android-sdk-icon.svg";
import Progressive from "../../../images/SoftwareDev/pwa-colored-icon.svg";
import flutter from "../../../images/SoftwareDev/flutter-colored-logo.svg";
import angular from "../../../images/SoftwareDev/AngularJS.webp";
import react1 from "../../../images/SoftwareDev/react-colored.svg";
import nodejs from "../../../images/SoftwareDev/nodejs-colored.svg";
import InsightSection from "./InsightSection";

const SaasDev = () => {
  return (
    <>
      <div className="CustomeDev">
        <section>
          <div className="main-banner">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <h1 className="Product-title">SaaS Development Services</h1>
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="content-para">
                    With our end-to-end SaaS development services, you can
                    create dependable, enterprise-className, and ready-to-market
                    apps that will improve the client experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="shape2 rotateme">
              <img src={shape2} alt="img" />
            </div>

            <div className="shape3">
              <img src={shape3} alt="shape" />
            </div>
            <div className="shape4">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape5">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape6 rotateme">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape7">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape8 rotateme">
              <img src={shape2} alt="shape" />
            </div>
          </div>
        </section>

        <section className="section-services">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row" style={{ marginBottom: "60px" }}>
              <div className="col-lg-12 col-md-12 text-center">
                <h2 className="section-head">Software as a Services</h2>
                <p className="section-para">
                  Our custom business software solutions provide a wide range of
                  services to various businesses.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ProductConsulting} alt="img" />
                  </div>
                  <h3>SaaS Development Consulting</h3>

                  <p>
                    Our SaaS solutions have a proven track record of generating
                    real, measurable business success for organizations. We
                    follow a carefully thought-out path for implementing SaaS
                    that uses cutting-edge tools, processes, and schedules
                    without straying from your long-term company objectives. Get
                    your SaaS ideas implemented and take advantage of promising
                    SaaS solutions to aid in the generation of ongoing income
                    development.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={prototype} alt="img" />
                  </div>
                  <h3>SaaS Prototype & Design</h3>

                  <p>
                    We use high-fidelity interactive prototypes to determine how
                    best to showcase the features and user experience of your
                    SaaS service. Using dynamically wireframed prototypes, our
                    team of expert User Experience (UX) designers investigates
                    behavioral patterns and complex issues ingrained in SaaS
                    software. Obtain SaaS apps that offer the best user
                    experience possible and are intuitive, eye-catching, and
                    futuristic.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={Maintenance1} alt="img" />
                  </div>
                  <h3>SaaS Application Modernization & Support</h3>

                  <p>
                    Utilize our round-the-clock SaaS modernization and support
                    services to maintain your SaaS application fully working and
                    experience hassle-free productivity. Our sole reliance on
                    cutting-edge technology helps us to accelerate the
                    development of SaaS software, resulting in SaaS products
                    with features that are optimized and fully functional in
                    accordance with your business objectives.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={SoftwareProTesting} alt="img" />
                  </div>
                  <h3>SaaS Platform Migration</h3>

                  <p>
                    We use predictive intelligence and machine learning in our
                    novel, data-driven strategy. You gain complete performance
                    improvement as a consequence, allowing your SaaS application
                    to reach its full potential. To resolve performance issues
                    with your SaaS software and increase user engagement, we
                    employ a variety of migration techniques and end-to-end
                    automated functional testing of apps.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ArchitectureD} alt="img" />
                  </div>
                  <h3>SaaS Development Outsourcing</h3>

                  <p>
                    You may use our knowledgeable technical teams to handle your
                    SaaS software development needs. We construct your SaaS
                    project within the allotted time limit without sacrificing
                    quality, managing product lifecycle management and
                    navigating product development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding-product">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row align-items-lg-center pt-md-3 pb-2 mb-2 mb-lg-4 mb-xl-2">
              <div className="col-md-12 col-lg-5 mb-4 mb-md-0 ">
                <img src={SaaSApplication} alt="img" className="leftimg" />
              </div>

              <div className="col-md-12 col-lg-6 offset-lg-1">
                <div className="pe-xl-2 me-md-2 me-lg-2">
                  <h2 className="h4 mb-3 mb-sm-2 design-and-content-header">
                    Leading provider of SaaS application development
                  </h2>

                  <p className="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    Discover our innovative methods for developing SaaS
                    applications and the measurable growth results they bring to
                    your business. In the cloud computing space, we are raising
                    the standard for SaaS integration services and helping a
                    range of companies, including startups, SMEs, ISVs, and
                    enterprises, turn a profit of millions of dollars.
                  </p>

                  <p className="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    Our team of knowledgeable SaaS developers is readily
                    available to help you achieve your goal of gaining a
                    competitive edge in the market. We provide you
                    state-of-the-art SaaS applications with better performance
                    at more affordable costs.
                  </p>

                  <p className="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    The development team of our SaaS applications considers
                    multiple factors starting from the concept and continuing
                    through design, prototyping, testing, and launch. You can
                    take advantage of flexible, stable, and secure SaaS
                    applications because the team has experience with SaaS
                    architectural implementation techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding clients-section ">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row align-items-center">
              <div className="col-lg-4 pr-lg-5">
                <h2 className="section-heading">
                  Our Leading Multinational Clients
                </h2>
                <p>
                  Join our diverse client base for a digital transformation
                  journey
                </p>
              </div>
              <div className="col-lg-8">
                <div className="clients-logo-list" style={{ display: "flex" }}>
                  <div className="logo-col">
                    <div className="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client9}
                        alt="MindLift Industries Logo"
                        title="MindLife"
                        className="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div className="logo-col">
                    <div className="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client10}
                        alt="worldbank Logo | senwell"
                        title="worldbank"
                        data-ll-status="loaded"
                        className="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div className="logo-col">
                    <div className="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client11}
                        alt="client11"
                        title="cisco"
                        data-ll-status="loaded"
                        className="entered lazyloaded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="industry-section section-padding-tech">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="mb-30">
              <h2 className="section-title text-center">
                Technologies & Platforms We Work With
              </h2>
              <p className="text-center">
                Our cleverly planned and built SaaS software products represent
                the fundamental capabilities of our company through aspects like
                operational efficiency, productivity, and scalability. In order
                to provide you with cutting-edge SaaS solutions,Senwell Group Private Limited, an offshore SaaS software development company, makes
                sure to implement cutting-edge technologies, programming
                languages, and a wide range of innovative tools.
              </p>
            </div>
            <div className="row">
              <div className="col-12">
                <Tabs
                  defaultActiveKey="frontend"
                  id="industry-tab"
                  className="mb-3 border-0"
                >
                  <Tab eventKey="frontend" title="Frontend">
                    <ul>
                      <li>
                        <img
                          src={js}
                          alt="javascript"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>JavaScript</span>
                      </li>
                      <li>
                        <img
                          src={Html}
                          alt="html5"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Html5</span>
                      </li>
                      <li>
                        <img
                          src={css3}
                          alt="css3"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Css3</span>
                      </li>
                      <li>
                        <img
                          src={angular}
                          alt="angular"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Angular</span>
                      </li>
                      <li>
                        <img
                          src={react1}
                          alt="react"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>React</span>
                      </li>
                      <li>
                        <img
                          src={emberjs}
                          alt="emberjs"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>EmberJs</span>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="backend" title="Backend">
                    <ul>
                      <li>
                        <img
                          src={java}
                          alt="java"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Java</span>
                      </li>
                      <li>
                        <img src={nestjs} alt="nestjs" className="w-100" />
                        <span>Nest Js</span>
                      </li>
                      <li>
                        <img
                          src={nodejs}
                          alt="nodejs"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>NodeJs</span>
                      </li>
                      <li>
                        <img
                          src={msdotnet}
                          alt="dotnet"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>.NET</span>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="mobile" title="Mobile">
                    <ul>
                      <li>
                        <img
                          src={flutter}
                          alt="flutter"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Flutter</span>
                      </li>
                      <li>
                        <img
                          src={react1}
                          alt="react"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>React</span>
                      </li>
                      <li>
                        <img
                          src={Ionic}
                          alt="ionic"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Ionic</span>
                      </li>
                      <li>
                        <img
                          src={Android}
                          alt="Android"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Android SDK</span>
                      </li>

                      <li>
                        <img
                          src={Progressive}
                          alt="pwa"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Progressive Web Apps</span>
                      </li>
                    </ul>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </section>

        <InsightSection />
      </div>
    </>
  );
};

export default SaasDev;
