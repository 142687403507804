/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";
import Home from "./Pages/Home";
import Company from "./Pages/Company/Company";
import BusinessModels from "./Pages/Company/BusinessModel";
import WebDevelopment from "./Pages/Services/WebDevelopment/WebDevelopment";
import Careers from "./Pages/Careers/Careers";
import Opportunities from "./Pages/Company/Opportunities";
import Development from "./Pages/Services/Development";
import Design from "./Pages/Services/Design/Design";

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./Modules/ScrollToTop";
import Technologies from "./Pages/Technologies/Technologies";
import Pricing from "./Pages/Company/Pricing";
import ScrollToTopButton from "./Modules/ScrollToTopButton";
import UnderConstruction from "./Pages/UnderConstruction";
import Testing from "./Pages/Services/Testing/Testing.js";
import Industries from "./Pages/Industries";
import Mvp from "./Pages/Services/Mvp";
import ProductDevelopment from "./Pages/Services/ProductDevelopment";
import Nav from "./Layouts/Nav";
import IndustriesnewMenu from "./Pages/IndustriesnewMenu";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Contact from "./Pages/Contact/Contact";
import PageNotFound from "./Pages/PageNotFound";
import JobPosition from "./Pages/job-details/JobPosition";
import Resumedata from "./Pages/resume/Resumedata";
import { useTranslation } from "react-i18next";
import Blogs from "./Pages/Blogs/Blogs";
import DigitalMarketing from "./Pages/Services/DigitalMarketing/DigitalMarketing";
import MobileDevelopment from "./Pages/Services/MobileDevelopment/MobileDevelopment";
import DevOps from "./Pages/Services/DevOps/DevOps";
import OutSourcing from "./Pages/Services/OutSourcing/OutSourcing";
import ITStaf from "./Pages/Services/IT-Staf/ITStaf";
import JavaFuture from "./Pages/Blogs/JavaFuture";
import ReactPopular from "./Pages/Blogs/ReactPopular";
import ObjectOrient from "./Pages/Blogs/ObjectOrient";
import LastFooter from "../src/Pages/Footer/LastFooter";
import Privacy from "./Pages/Privacy/Privacy";
import Login from "./Admin/Login/Login";
import { Helmet } from "react-helmet";
import Terminal from "./Terminal";
import SoftwareProduct from "./Pages/NewServices/SoftwareProduct/SoftwareProduct.jsx";
import CustomeSoftware from "./Pages/NewServices/CustomeSoftware/CustomeSoftware.jsx";
import SaasDev from "./Pages/NewServices/SaaSDev/SaasDev.jsx";
import ApplicationDev from "./Pages/NewServices/ApplicationDev/ApplicationDev.jsx";
import SoftwareTesting from "./Pages/NewServices/SoftwareTesting/SoftwareTesting.jsx";
import UiDesign from "./Pages/NewServices/UiDesign/UiDesign.jsx";
import WebDev from "./Pages/NewServices/WebDev/WebDev.jsx";
import Cloud from "./Pages/NewServices/Cloud/Cloud.jsx";
import ThankYouPage from "./Pages/ThankYouPage/ThankYouPage.js";
import Annoucement from "./Pages/Blogs/Annoucement.js";
import BusinessApi from "./Pages/Blogs/BusinessApi.js";

const App = () => {
  const [slug, setSlug] = useState(false);
  const getSlug = (slug) => {
    setSlug(slug);
  };
  const { Translation } = useTranslation;
  return (
    <>
      <Helmet>
        <title>Senwell Group Private Limited</title>
        <meta
          name="description"
          content="Senwell Group Private Limited is a leading web and app development firm with expertise in staff augmentation, dedicated to empowering businesses to achieve substantial growth."
        />
        <meta
          name="keywords"
          content="web application development,	
      web designer,	
      IT company,	
      IT services,	
      IT support,	
      mobile and web application development,	
      Cybersecurity,
      web application development services,	
      web application development company in india,	
      web application development company,
      web development company,
      web development company in india,	
      web development agency,
      web development company in pune,
      software development company,
      custom software development company,
      it staffing,
      it staffing services,	
      software development company in india,
      software development company in pune,
      software development agency,
      custom software development agency,
      software outsourcing services,	
      web development outsourcing,
      software development agency in india,
      best software development agency,
      software development agency india,
      software development agency pune,	
      healthcare software development agency,
      healthcare software development company,
      healthcare software development,
      top software development agency,
      outsource software development agency,
      kotlin software development agency,
      flutter software development agency,
      angular software development agency,
      react native software development agency,
      hire react js software development agency,
      react js software development agency,
      transportation and logistics software development agency,	
      e-commerce software development agency,
      b2b software development agency,
      software development agency for startups,
      financial software development agency,
      hire custom software development agency,
      hire android software development agency,
      fintech software development agency,
      blockchain software development agency,
      corporate software development agency,
      crm software development agency in india,
      fintech application development services,
      top software development companies in world,
      digital marketing company,
      digital marketing company in india,
      digital marketing company in pune,
      top digital marketing company in india,	
      best digital marketing company,	
      digital marketing company india,	
      digital marketing agency,	
      digital marketing agency in india,	
      best digital marketing agency,	
      digital marketing agency in pune,
      digital marketing services,
      digital marketing services in india,
      digital marketing services in pune "
        />
        <meta name="author" content="Senwell Group Private Limited" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <link rel="canonical" href="https://senwellsys.com/" />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="https://senwellsys.com/sitemap.xml"
        />
      </Helmet>

      <ScrollToTopButton />
      <Router>
        <ScrollToTop />
        <Nav slug={slug} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/terminal" element={<Terminal />} />
          <Route exact path="/company" element={<Company />} />
          <Route
            exact
            path="/outsourcing-pricing-models"
            element={<BusinessModels />}
          />
          <Route exact path="/careers" element={<Careers />} />
          <Route
            exact
            path="/careers/opportunity"
            element={<Opportunities />}
          />
          <Route exact path="/services/ui-ux-design" element={<Design />} />
          <Route
            exact
            path="/services/mobile-development"
            element={<MobileDevelopment />}
          />
          <Route
            exact
            path="/services/out-sourcing"
            element={<OutSourcing />}
          />
          <Route exact path="/services" element={<UnderConstruction />} />
          <Route exact path="/services/mvp-development" element={<Mvp />} />
          <Route exact path="/technologies" element={<UnderConstruction />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/services/devops" element={<DevOps />} />
          <Route exact path="/industries" element={<UnderConstruction />} />
          <Route
            exact
            path="/:slug/:name"
            element={<Technologies getSlug={getSlug} />}
          />
          <Route exact path="/services/testing-and-qa" element={<Testing />} />
          <Route
            exact
            path="/services/digital-marketing"
            element={<DigitalMarketing />}
          ></Route>
          <Route exact path="/services/:name" element={<WebDevelopment />} />
          <Route exact path="/industries/:name" element={<Industries />} />
          <Route exact path="/:slug/" element={<IndustriesnewMenu />} />
          <Route exact path="/job/jobposition/:id" element={<JobPosition />} />
          <Route exact path="/job/resume" element={<Resumedata />} />
          <Route
            exact
            path="/services/product-development"
            element={<ProductDevelopment />}
          />
          <Route exact path="/projects" element={<UnderConstruction />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/java-future-blog" element={<JavaFuture />} />
          <Route exact path="/react-future-blog" element={<ReactPopular />} />
          <Route exact path="/object-orient-blog" element={<ObjectOrient />} />
          <Route exact path="/api-business-blog" element={<BusinessApi />} />

          <Route
            path="/new-logo-announcement-introducing-senwell-group-new-brand-identity"
            element={<Annoucement />}
          />
          <Route
            exact
            path="/services/it-staff-augmentation"
            element={<ITStaf />}
          />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/admin/login" element={<Login />} />
          <Route path="/*" element={<PageNotFound />} />
          <Route exact path="/softwareproduct" element={<SoftwareProduct />} />
          <Route
            exact
            path="/custom-software-development"
            element={<CustomeSoftware />}
          />
          <Route
            exact
            path="/saas-development-services"
            element={<SaasDev />}
          />
          <Route
            exact
            path="/mobile-app-development-services"
            element={<ApplicationDev />}
          />
          <Route
            exact
            path="/qa-and-software-testing-services"
            element={<SoftwareTesting />}
          />
          <Route exact path="/ui-ux-design-services" element={<UiDesign />} />
          <Route exact path="/web-development" element={<WebDev />} />
          <Route exact path="/cloud-consulting-services" element={<Cloud />} />
          <Route exact path="/thankyou" element={<ThankYouPage />} />
        </Routes>

        <LastFooter />
      </Router>
    </>
  );
};

export default App;
