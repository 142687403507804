import React from "react";
import "./WebDev.css";
import shape2 from "../../../images/SoftwareDev/shape2.svg";
import shape3 from "../../../images/SoftwareDev/shape3.svg";
import shape4 from "../../../images/SoftwareDev/shape4.svg";
import customeW from "../../../images/SoftwareDev/customer website development.png";
import EcommerceW from "../../../images/SoftwareDev/ecommerce web development service.png";
import WebPortal from "../../../images/SoftwareDev/web portal development service (2).png";
import WebPortal2 from "../../../images/SoftwareDev/web portal development service.png";
import WebMigration from "../../../images/SoftwareDev/website migration service.png";
import ApplicationMord from "../../../images/SoftwareDev/application modernization.png";
import FullStack from "../../../images/SoftwareDev/Full stack.png";
import client9 from "../../../images/Pastproject/client9.webp";
import client10 from "../../../images/Pastproject/client10.webp";
import client11 from "../../../images/Pastproject/CiscoLogo.png";
import InsightSection from "./InsightSection";
import js from "../../../images/SoftwareDev/javascript-logo.svg";
import html5 from "../../../images/SoftwareDev/html5-colored-logo.svg";
import json from "../../../images/SoftwareDev/Json-colored-icon.svg";
import angular1 from "../../../images/SoftwareDev/AngularJS.webp";
import jquery from "../../../images/SoftwareDev/jquery-colored-icon.svg";
import nodejs from "../../../images/SoftwareDev/nodejs-colored.svg";
import java from "../../../images/SoftwareDev/Java-icon (1).svg";
import mongodb from "../../../images/SoftwareDev/mongodb.svg";
import express from "../../../images/SoftwareDev/Express.js-icon.webp";

const WebDev = () => {
  return (
    <>
      <div className="CustomeDev">
        <section>
          <div className="main-banner">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <h1 className="Product-title">Web Application Development</h1>
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="content-para">
                    To make a significant difference in your web development
                    projects, design, develop, and implement web applications.
                    Senwell Group Private Limited' development methodology helps customers
                    from the very beginning by assessing disruptive web
                    development challenges and ending with the creation of a
                    user-friendly, powerful, and fully functional web solution.
                  </p>
                </div>
              </div>
            </div>

            <div class="shape2 rotateme">
              <img src={shape2} alt="img" />
            </div>

            <div className="shape3">
              <img src={shape3} alt="shape" />
            </div>
            <div className="shape4">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape5">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape6 rotateme">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape7">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape8 rotateme">
              <img src={shape2} alt="shape" />
            </div>
          </div>
        </section>

        <section className="section-services">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row" style={{ marginBottom: "60px" }}>
              <div className="col-lg-12 col-md-12 text-center">
                <h2 className="section-head">
                  Web Application Development Services
                </h2>
                <p className="mob-section-para">
                  Gain a competitive edge by quickening the development of your
                  web applications with Senwell Group Private Limited
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={customeW} alt="img" />
                  </div>
                  <h3>Front-end Web Development</h3>

                  <p>
                    Make use of the reusable components and implement
                    component-driven architecture with cutting-edge front-end
                    technologies such as Angular, Vue, and React.js. Senwell Group Private Limited develops web applications and skillfully blends
                    necessary functionalities with gorgeous user interfaces.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={EcommerceW} alt="img" />
                  </div>
                  <h3>Back-end Development</h3>

                  <p>
                    Make your application scalable by utilizing the skilled pool
                    of backend web developers to switch between its various
                    features with ease. We provide SQL and NoSQL databases for
                    dependable data storing and retrieval using Microservices,
                    as well as AWS and Azure services for hosting the backend
                    services on the cloud.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={WebPortal} alt="img" />
                  </div>
                  <h3>SaaS Application Development</h3>

                  <p>
                    Go in-depth with the client to fully comprehend the extent
                    of the web application and its scalability. Our skilled and
                    technically sound web app development team can provide
                    multi-tenant, secure SaaS application development solutions
                    that will ultimately benefit businesses.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={WebPortal2} alt="img" />
                  </div>
                  <h3>Progressive Web Apps</h3>

                  <p>
                    In line with the demands of contemporary app development, we
                    produce native web applications that boast improved
                    technological features, quicker application loads, highly
                    interactive visual content, and simple navigation between
                    various features.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={WebMigration} alt="img" />
                  </div>
                  <h3>Ecommerce Web App Development Company</h3>

                  <p>
                    Build and customize eCommerce web apps with Shopify,
                    Magento, and unique frameworks to increase online visibility
                    and business growth. Our skilled web developers create
                    user-friendly, safe, and quick-to-launch eCommerce
                    applications to guarantee user engagement and growth.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ApplicationMord} alt="img" />
                  </div>
                  <h3>Custom Web Application Development services</h3>

                  <p>
                    We assist you in providing outstanding web development
                    services along with knowledgeable resources and
                    technological aptitude to support your overall business
                    plan. Our proficiency in developing customized web
                    applications guarantees interoperability, expandability, and
                    user-friendly interfaces.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding-product">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row align-items-lg-center pt-md-3 pb-2 mb-2 mb-lg-4 mb-xl-2">
              <div className="col-md-12 col-lg-5 mb-4 mb-md-0 ">
                <img src={FullStack} alt="img" className="leftimg" />
              </div>

              <div className="col-md-12 col-lg-6 offset-lg-1">
                <div className="pe-xl-2 me-md-2 me-lg-2">
                  <h2 className="h4 mb-3 mb-sm-2 design-and-content-header">
                    Web Application Development
                  </h2>

                  <p class="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    As a top web application development company, we boost
                    client satisfaction by utilizing our extensive experience
                    developing and providing specialized web application
                    development services. We assist you in putting the
                    appropriate technologies into practice where they will most
                    effectively add value to your company.
                  </p>

                  <p class="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    Finding out "What does the client want?" is our main goal.
                    Our developers create and provide outstanding web app
                    development services for long-term business growth, starting
                    from ideation and ending with testing and delivery.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding clients-section ">
          <div class="container" style={{ left: "0", border: "none" }}>
            <div class="row align-items-center">
              <div class="col-lg-4 pr-lg-5">
                <h2 class="section-heading">
                  Our Leading Multinational Clients
                </h2>
                <p>
                  Join our diverse client base for a digital transformation
                  journey
                </p>
              </div>
              <div class="col-lg-8">
                <div class="clients-logo-list" style={{ display: "flex" }}>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client9}
                        alt="MindLift Industries Logo"
                        title="MindLife"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client10}
                        alt="worldbank Logo | senwell"
                        title="worldbank"
                        data-ll-status="loaded"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client11}
                        alt="client11"
                        title="cisco"
                        data-ll-status="loaded"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-services tools-section pb-0">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div class="row top-row">
              <div class="col-md-11 mx-auto text-center">
                <h2 class="section-heading">
                  Providing Technically Competent Next-Generation Web
                  Development
                </h2>
                <p>
                  We are specialists in every technology needed to offer
                  outsourced web development services to customers in every
                  industry vertical. Selecting the right tech stack might be
                  challenging, but our team of knowledgeable web developers can
                  help you make the best decisions so you can create a
                  feature-rich, high-quality web application.
                </p>
              </div>
            </div>

            <div class="row bottom-row">
              <div class="col-md-12">
                <div class="tools-title-list">
                  <div
                    data-id="#tools-1"
                    class="tools-title active"
                    style={{ border: "none" }}
                  ></div>
                </div>
                <div class="tools-content-list">
                  <div
                    id="tools-1"
                    class="tools-content active"
                    style={{ border: "none" }}
                  >
                    <div class="tools-icon-list">
                      <ul>
                        <li>
                          <img src={js} alt="img" />
                          <span>JavaScript</span>
                        </li>

                        <li>
                          <img src={html5} alt="img" />
                          <span>HTML5</span>
                        </li>

                        <li>
                          <img src={json} alt="img" />
                          <span>Json</span>
                        </li>

                        <li>
                          <img src={angular1} alt="img" />
                          <span>AngularJS</span>
                        </li>

                        <li>
                          <img src={jquery} alt="img" />
                          <span>jQuery</span>
                        </li>

                        <li>
                          <img src={express} alt="img" />
                          <span>Express.js</span>
                        </li>

                        <li>
                          <img src={nodejs} alt="img" />
                          <span>Node.js</span>
                        </li>

                        <li>
                          <img src={java} alt="img" />
                          <span>Java</span>
                        </li>

                        <li>
                          <img src={mongodb} alt="img" />
                          <span>MongoDB</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <InsightSection />
      </div>
    </>
  );
};

export default WebDev;
