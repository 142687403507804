import React, { useEffect, useState } from "react";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Box, Typography } from "@material-ui/core";
import Cronage from "../../images/Pastproject/Cronage.png";
import Fooz from "../../images/Pastproject/Fooz.png";
import Fork from "../../images/Pastproject/Fork1.png";
import Lotto from "../../images/Pastproject/Lotto.png";
import Lumiere from "../../images/Pastproject/Lumiere.png";
import PastProjects from "../../SharedModule/ProjectPortfollio";
import parsl from "../../images/Pastproject/parsl.JPG";
import Virus from "../../images/Pastproject/Virus.png";
import Mindlife from "../../images/Pastproject/MindLife.png";
import Commodity from "../../images/Pastproject/commodity master1.png";
import { useMediaQuery } from "@material-ui/core";
import { HomePageData } from "../../API/HomeApi";
import { useTheme } from "@mui/material/styles";
import Lottie from "react-lottie";
import animationData from "../../assets/Portfolio.json";
import "../../Pages/Portfolio/Portfolio.css";
import { Link } from "react-router-dom";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  mainContainer: {
    width: "90%",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  CaseStudies: {
    margin: "1rem 0rem",
    width: "100%",
  },
  bimsmithBlock: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
    height: "100%",
    padding: "10px 10px",
    boxShadow: "0 23px 34px #00000014",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },

  head: {
    fontSize: "3rem",
    fontFamily: "'Manrope', sans-serif",
    textTransform: "uppercase",
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
      textAlign: "center",
    },
  },
  centerText: {
    textAlign: "center",
    color: "gray",
    marginBottom: "10px",
    fontSize: "23px",
  },
  centerTextPara: {
    textAlign: "justify",
    fontWeight: "400",
    lineHeight: "1.5",
    wordSpacing: "-0.1em",
    hyphens: "auto",
  },
  imageTitle: {
    textAlign: "center",
    fontFamily: "'Manrope', sans-serif",
    lineHeight: "35px",
    marginBottom: "1rem",
    color: "black",
    marginTop: "10px",
    fontSize: "35px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  bimsmithPara: {
    textAlign: "justify",
    lineHeight: "24px",
    marginBottom: "25px",
    width: "350px",
  },
  BtnWrapper: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "center",
  },
  Images: {
    width: "100%",
    height: "auto",
    marginBottom: "5px",
    marginRight: "5px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },
}));
export default function Portfolio() {
  const classNamees = useStyles();
  const industries = [
    { industry: "Banking & Finance" },
    { industry: "E-com & Retail" },
    { industry: "Education" },
    { industry: "Healthcare" },
    { industry: "Media & Entertainment" },
    { industry: "Social Networks" },
  ];
  const technologies = [
    { tech: "React" },
    { tech: "C#" },
    { tech: ".net" },
    { tech: "web-design" },
    { tech: "Angular" },
    { tech: "node.js" },
  ];
  const services = [
    { service: "Android" },
    { service: "Design" },
    { service: "Web" },
    { service: "Desktop" },
    { service: "ios" },
    { service: "Testing" },
  ];

  const portfolio = [
    {
      img: Cronage,
      heading: "Cronage ",
      technologies: " Angular, Bootstrap, HTML, CSS",
      paragraph:
        "A well Designed website for the manufacturing industry called Cronage. Cronage Pvt. Ltd, known for perfection in Quality, Delivery and Prices, eager to serve our customers in a better way, with continuous improvements. ",
    },
    {
      img: Fooz,
      heading: "Fooz",
      technologies: " React, Redux, Node.js, Bootstrap",
      paragraph:
        "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World.",
    },
    {
      img: Fork,
      heading: "Fork-Freight",
      technologies: "Angular, Asp.Net, Node.",
      paragraph:
        "Web application for transportation Industries. Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
    },
    {
      img: Lotto,
      heading: "LottoSocial",
      technologies: " Angular, Ionic, Node.js,  ",
      paragraph:
        "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
    },
    {
      img: Lumiere,
      heading: "Lumiere32- B2B Supplier Marketplace",
      technologies: " Angular, MYSQL, NestJs",
      paragraph:
        "E-commerce Website or marketplace for dental/medical products, connecting manufacturers, dealers and dental/medical professionals.",
    },
    {
      img: parsl,
      heading: "Parsl",
      technologies: "Angular, Node.js",
      paragraph:
        "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
    },
    {
      img: Virus,
      heading: "VirusGeeks",
      technologies: " React, NextJs, NestJs",
      paragraph:
        "VirusGeeks, a pioneering BioHealth Technology platform, serves as a cutting-edge healthcare solution, specializing in comprehensive Covid-19 testing services from start to finish. Our platform is dedicated to transforming the healthcare landscape by seamlessly integrating technology and biology. ",
    },
    {
      img: Mindlife,
      heading: "MindLife",
      technologies: "Angular, MySQL, JavaScript",
      paragraph:
        "Next-generation Solutions in Mental Health & related Mind-Body conditions for both Clinicans and Patients",
    },
    {
      img: Commodity,
      heading: "Commodity Master",
      technologies: "Angular, Next js",
      paragraph:
        "Commodity Master provides our clients with reliable data, forecasts, analysis of key commodities, raw material price tracking, spend analysis, sourcing services and designing best-in-class, Name procurement processes that revolves around profitability for us to help make sound business decisions.",
    },
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [ProjectPortfolioData, setProjectPortfolioData] = useState([]);

  useEffect(() => {
    HomePageData?.map((value) => {
      setProjectPortfolioData(value.ProjectPortfollio);
    });
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <section className="PortfolioHeader-sections">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="Portfolio-banner-content">
                <h1>Our Portfolio</h1>
                <p className="sub-p">
                  Explore our portfolio to discover how we've helped businesses
                  like yours thrive through technology. From custom software
                  development to seamless network solutions, we bring a blend of
                  creativity and technical proficiency to every project. Whether
                  you're a startup or an established enterprise, our IT
                  solutions are tailored to your unique needs.
                </p>
                <div className="Portfolio-banner-button">
                  <Link to="/contact">
                    <a className="Portfolio-theme-btn">Let's Talk</a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 image-animation">
              <div className="image" style={{ pointerEvents: "none" }}>
                <Lottie options={defaultOptions} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Container className={classNamees.mainContainer}>
        <PastProjects ProjectPortfolio={ProjectPortfolioData} />
        <Grid container spacing={2} className={classNamees.CaseStudies}>
          {portfolio?.map((testCases, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                <Box className={classNamees.bimsmithBlock}>
                  <div>
                    <img
                      className={classNamees.Images}
                      src={testCases.img}
                      alt="pastProjectImage"
                    />
                  </div>
                  <div>
                    <Typography variant="h3" className={classNamees.imageTitle}>
                      {testCases.heading}
                    </Typography>
                    <h6 className={classNamees.centerText}>
                      Technologies: {testCases.technologies}
                    </h6>
                    <Typography
                      className={
                        classNamees.bimsmithPara && classNamees.centerTextPara
                      }
                    >
                      {testCases.paragraph}
                    </Typography>
                  </div>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </>
  );
}
