import React from "react";
import { Container, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Work from "@material-ui/icons/Work";
import { Link } from "react-scroll";
import DocumentMeta from "react-document-meta";

const useStyle = makeStyles((theme) => ({
  careerMainHeading: {
    maxWidth: "665px",
    fontFamily: "'Manrope', sans-serif",
    textAlign: "center",
    margin: "0 auto",
    paddingBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-69px",
      fontSize: "2rem",
    },
  },
  meetUsBlock: {
    position: "relative",
    paddingTop: "40px",
    backgroundColor: "#fafafa",
    width: "30%",
    minWidth: "312px",
    minHeight: "250px",
    boxShadow: "7px 7px 16px 0px #e1d8d8",
    margin: "20px 16px 20px 16px ",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  careerBlock: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  imgBlock: {
    textAlign: "center",
  },

  meetUsHeading: {
    marginBottom: "4px",
    fontSize: "25px",
    textAlign: "center",
    color: "#202020",
    fontFamily: "'Manrope', sans-serif",
  },
  meetUsPara: {
    color: "#3f3f3f",
    textAlign: "center",
    fontFamily: "'Manrope', sans-serif",
    fontSize: "16px",
    padding: "0 5px",
  },
  ArrowDownward: {
    color: "#3D8ED7",
    width: "50px",
    cursor: "pointer",
    padding: "0.5rem",
    height: "50px",
    borderRadius: "50%",
    transition: "all 400ms",
    boxShadow: "3px 8px 15px 0px #cec5c5",
    backgroundColor: "#f0efef",
    position: "absolute",
    bottom: "-1rem",
    right: "12rem",
    [theme.breakpoints.down("sm")]: {
      right: "8rem",
    },
    "&:hover": {
      backgroundColor: "#2ba2de",
      color: "white",
    },
  },
  mainWrapper: {
    paddingTop: "8rem !important",
  },
}));

const meta = {
  title: "Join Our Team",
  description:
    "At Senwell Group Private Limited Empowering amazing individuals to thrive. Join us for career growth, a vibrant community, and unleash your brilliance!.",
  canonical: "https://isearch.co.in/careers",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "react,meta,document,html,tags",
    },
    image:
      "https://senwellsys.com/static/media/CareerNew2.9db0208eca559091332b.png",
    type: "article",
  },
};

const Career = () => {
  const classNamees = useStyle();
  return (
    <>
      //{" "}
      <DocumentMeta {...meta}>
        <Container maxWidth="lg" className={classNamees.mainWrapper}>
          <Typography variant="h4" className={classNamees.careerMainHeading}>
            Let’s build industry-changing projects together!
          </Typography>
          <Box className={classNamees.careerBlock}>
            <Box className={classNamees.meetUsBlock}>
              <div className={classNamees.imgBlock}>
                <PeopleAlt
                  style={{ color: "#3D8ED7", width: "56px", height: "56px" }}
                />
              </div>
              <Typography variant="h6" className={classNamees.meetUsHeading}>
                Meet Us
              </Typography>
              <p className={classNamees.meetUsPara}>
                Learn about the culture here at Senwell Group Private Limited
              </p>
              <div style={{ textAlign: "center" }}>
                <Link to="meetus" spy={true} smooth={true}>
                  <ArrowDownward className={classNamees.ArrowDownward} />
                </Link>
              </div>
            </Box>

            <Box className={classNamees.meetUsBlock}>
              <div className={classNamees.imgBlock}>
                <Work
                  style={{ color: "#3D8ED7", width: "56px", height: "56px" }}
                />
              </div>
              <Typography variant="h6" className={classNamees.meetUsHeading}>
                Job Openings
              </Typography>
              <p className={classNamees.meetUsPara}>
                Find a suitable job that matches your skills
              </p>
              <div style={{ textAlign: "center" }}>
                <Link to="openings" spy={true} smooth={true}>
                  <ArrowDownward className={classNamees.ArrowDownward} />
                </Link>
              </div>
            </Box>
          </Box>
        </Container>
        //{" "}
      </DocumentMeta>
    </>
  );
};

export default Career;
