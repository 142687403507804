import React from "react";
import { CompanyData } from "../../API/CompanyApi";
import Skill from "../../SharedModule/Skills";
import GetInTouch from "../../SharedModule/GetInTouch";
import Whysenwell from "../../SharedModule/Whysenwell";
import Lottie from "react-lottie";
import animationData from "../../assets/AboutAnimation.json";
import { Link } from "react-router-dom";

import "./Company.css";
const Company = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {CompanyData?.map((value) => (
        <>
          <section className="Company-header-sections">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 Company-banner-col">
                  <div className="Company-banner-content">
                    <h1>About Us</h1>
                    <p className="sub-p">
                      At Senwell Group Private Limited, we are your partners in navigating
                      the dynamic landscape of technology. As a leading
                      Information Technology company, we specialize in
                      delivering tailored solutions that empower businesses to
                      thrive in the digital age.
                    </p>
                    <div className="Company-banner-button">
                      <Link to="/contact">
                        <a className="Company-theme-btn">Let's Talk</a>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 about-image">
                  <div className="image" style={{ pointerEvents: "none" }}>
                    <Lottie options={defaultOptions} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Skill Skill={value.skill} />
          <Whysenwell Whysenwell={value.whysenwell} />
          <GetInTouch GetInTouch={value.GetInTouch} />
        </>
      ))}
    </>
  );
};

export default Company;
